import { Observable, of } from 'rxjs';

export abstract class Logger {
  location: string = '';
  constructor(loc: string) {
    this.location = loc;
  }
  abstract log(msg: string): Observable<boolean>;
  abstract clear(): Observable<boolean>;
}

export class ConsoleLogger extends Logger {

  public log(msg: string): Observable<boolean> {
    console.log(msg);
    return of(true);
  }

  public clear(): Observable<boolean> {
    console.clear();
    return of(true);
  }
}

export class WebAPILogger extends Logger {

  public log(msg: string): Observable<boolean> {
    console.log(msg + ' - WEBAPI');
    return of(true);
  }

  public clear(): Observable<boolean> {
    console.clear();
    return of(true);
  }
}

export class LocalStorageLogger extends Logger {

  public log(msg: string): Observable<boolean> {
    let ret: boolean = false;
    let values: string[];

    try {
      values = JSON.parse(localStorage.getItem(this.location)) || [];
      values.push(msg);
      localStorage.setItem(this.location, JSON.stringify(values));
      ret = true;
    } catch (ex) {
      console.log(ex);
    }

    return of(ret);
  }

  public clear(): Observable<boolean> {
    localStorage.removeItem(this.location);
    return of(true);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IConfig } from '../models/config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private configPath = '../assets/jsons/config.json';
  private configuration: IConfig;
  private isConfigLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private httpClient: HttpClient) {
    httpClient.get<IConfig>(this.configPath).subscribe(config => {
      this.configuration = config;
      this.isConfigLoaded.next(true);
    });
   }

   getGlobalConfig(): IConfig {
     return this.configuration;
   }

   getConfigStatus(): Observable<boolean> {
    return this.isConfigLoaded.asObservable();
   }
}

import { Injectable } from '@angular/core';
import { OAuthService, AuthConfig, JwksValidationHandler } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { PRIMARY_HOST_DOMAIN } from '../shared/constants/app.constants';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  authConfig: AuthConfig = {};
  errorMessage: any;

  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isEligible: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  constructor(private oauthService: OAuthService,
              private cookieService: CookieService,
              private configService: ConfigService) {
  }
  
  public getLoginStatus(): Observable<boolean> {
    return this.isAuthenticated.asObservable();
  }

  public setLoginStatus(status: boolean): void {
    this.isAuthenticated.next(status)
  }

  public getEligibilityStatus(): Observable<boolean> {
    return this.isEligible.asObservable();
  }

  public setEligibilityStatus(status: boolean): void {
    this.isEligible.next(status)
  }
}

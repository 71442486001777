import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { VehicleService } from '../services/vehicle.service';
import { MakeResult, ModelNameResult, BodyStyleResult } from '../models/vinmaster-response';
import { Router } from '@angular/router';
import { ModelSearchRequest, VinSearchRequest } from '../models/vinmaster-request';
import * as fromVehicle from '../vehicle/state/vehicle.reducer';
import { Store, select } from '@ngrx/store';
import { VehicleActionTypes } from '../vehicle/state/vehicle.actions';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-search-controls',
  templateUrl: './search-controls.component.html',
  styleUrls: ['./search-controls.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchControlsComponent implements OnInit {
  @ViewChild('makeRadio', { static: false }) makeRadio: ElementRef;

  public years: number[] = [];
  public isVinSearchSelected: boolean = false;
  public vinSearchForm: FormGroup;
  public modelSearchForm: FormGroup;
  public makeResults: MakeResult[];
  public modelNameResults: ModelNameResult[];
  public bodyStyleResults: BodyStyleResult[];
  public isMakeLoading = false;
  public isModelLoading = false;
  public isBodyStyleLoading = false;

  private _toString?: Function;
  lastIndex: number = -1;

  vinControl = new FormControl('', [Validators.required, vinTrimming]);

  constructor(private formBuilder: FormBuilder,
    private vehicleService: VehicleService,
    private router: Router,
    private store: Store<fromVehicle.State>,
    private cd: ChangeDetectorRef) {
    this.vinSearchForm = this.formBuilder.group({
      year: new FormControl('', [Validators.required]),
      vin: this.vinControl
    });

    this.modelSearchForm = this.formBuilder.group({
      year: new FormControl('', [Validators.required]),
      make: new FormControl('', [Validators.required]),
      model: new FormControl('', [Validators.required]),
      bodyStyle: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    for (let i = 1995; i <= new Date().getFullYear() + 1; i++) {
      this.years.push(i);
    }
    this.years.reverse();
    this.store.pipe(select(fromVehicle.getResetSearchontrolState)).subscribe(newState => {
      if (newState) {
        this.makeRadio.nativeElement.checked = true;
        this.onSearchSelectionChange('makesearch');
      }
    });
    setTimeout(() => this.selectDropdownfisrtOption(), 100);
  }

  vinChange() {
    this.cd.detectChanges();
  }

  public onSearchSelectionChange(searchOption: string): void {
    this.isVinSearchSelected = searchOption === 'vinsearch';
    //Adobe Analytics changes
    this.determineSearch(this.isVinSearchSelected);
    this.makeResults = [];
    this.modelNameResults = [];
    this.bodyStyleResults = [];
    this.modelSearchForm.reset();
    this.vinSearchForm.reset();
    this.router.navigate(['/index.html']);
    this.store.dispatch({
      type: VehicleActionTypes.PriceNewSearch,
      payload: false
    });
    this.cd.detectChanges();
    this.selectDropdownfisrtOption();
  }

  public onVinYearChange(year: string): void {
    this.vinSearchForm.controls.year.setValue(year);
    this.cd.detectChanges();
    //Focus Textbox on selecting year on VIN search
    document.getElementById("vin").focus();
  }
  public onYearChange(year: string): void {
    this.isMakeLoading = true;
    this.makeResults = null;
    this.modelNameResults = null;
    this.bodyStyleResults = null;
    this.modelSearchForm.controls.make.setValue(undefined);
    this.modelSearchForm.controls.model.setValue(undefined);
    this.modelSearchForm.controls.bodyStyle.setValue(undefined);
    this.modelSearchForm.controls.year.setValue(year);
    this.vehicleService.makeSearch(year).subscribe(result => {
      this.makeResults = result;
      this.isMakeLoading = false;
      this.cd.detectChanges();
      var eleMake = document.getElementById("selMake");
      var eleChild = eleMake.getElementsByClassName("dropdown-root");
      eleChild[0].className = eleChild[0].className;
    });
    this.cd.detectChanges();

  }

  public onMakeChange(makeResult: MakeResult): void {
    this.isModelLoading = true;
    this.modelNameResults = null;
    this.bodyStyleResults = null;
    this.modelSearchForm.controls.model.setValue(undefined);
    this.modelSearchForm.controls.bodyStyle.setValue(undefined);
    this.modelSearchForm.controls.make.setValue(makeResult.make);
    this.vehicleService.modelNameSearch(makeResult.make, this.modelSearchForm.value.year).subscribe(result => {
      this.modelNameResults = result;
      this.isModelLoading = false;
      this.cd.detectChanges();
      var eleMake = document.getElementById("selModel");
      var eleChild = eleMake.getElementsByClassName("dropdown-root");
      eleChild[0].className = eleChild[0].className;
    });
    this.cd.detectChanges();
  }

  public onModelNameChange(model: ModelNameResult): void {
    this.isBodyStyleLoading = true;
    this.bodyStyleResults = null;
    this.modelSearchForm.controls.bodyStyle.setValue(undefined);
    this.modelSearchForm.controls.model.setValue(model.modelName);
    this.vehicleService.bodyStyleSearch(model.modelName, this.modelSearchForm.value.make, this.modelSearchForm.value.year).subscribe(result => {
      this.bodyStyleResults = result;
      this.isBodyStyleLoading = false;
      this.cd.detectChanges();
      var eleMake = document.getElementById("selBody");
      var eleChild = eleMake.getElementsByClassName("dropdown-root");
      eleChild[0].className = eleChild[0].className;
    });
    this.cd.detectChanges();
  }

  public onBodyStyleChange(body: BodyStyleResult): void {
    this.modelSearchForm.controls.bodyStyle.setValue(body.bodyStyle);
    this.cd.detectChanges();
  }

  public onVinSearchSubmit(vinSearchData: VinSearchRequest) {
    /*
    this.store.dispatch({
      type: VehicleActionTypes.HideWelcomeMessage,
      payload: true
    });
    */

    vinSearchData.vin = vinSearchData.vin.trim();
    this.updatedigitalData("VIN Search");

    this.store.dispatch({
      type: VehicleActionTypes.PriceNewSearch,
      payload: false
    });

    this.store.dispatch({
      type: VehicleActionTypes.VinSearchLoad,
      payload: vinSearchData
    });

    this.store.dispatch({
      type: VehicleActionTypes.ResetSearchControl,
      payload: false
    });
  }

  public onModelSearchSubmit(modelSearchData: ModelSearchRequest) {
    /*
    this.store.dispatch({
      type: VehicleActionTypes.HideWelcomeMessage,
      payload: true
    });
    */
    this.updatedigitalData("Make Model Search");
    this.store.dispatch({
      type: VehicleActionTypes.PriceNewSearch,
      payload: false
    });


    this.store.dispatch({
      type: VehicleActionTypes.ModelSearchLoad,
      payload: modelSearchData
    });
    this.router.navigate(['/vehicles']);
  }

  public onVehicleNotFound() {
    this.router.navigate(['/vehicles/vehiclenotfound']);
  }

  @HostListener('window:keydown', ['$event'])
  keydownHandler(event) {
    var inp = String.fromCharCode(event.keyCode).toLowerCase();
    var items = [];

    var dropDown = document.getElementsByClassName("open");
    if (dropDown.length > 0) {
      var liOptions = document.querySelectorAll('.open .dropdown-menu li');

      for (var i = 0; i < liOptions.length; i++) {
        (liOptions[i].getElementsByTagName("div"))[0].classList.remove("active");
        var optionVal = (liOptions[i].getElementsByTagName("div"))[0].innerText.toLowerCase();
        items.push(optionVal);
      }
      let value = this.find(items, inp);

      for (var i = 0; i < liOptions.length; i++) {
        var optionVal1 = (liOptions[i].getElementsByTagName("div"))[0].innerText.toLowerCase();
        if (optionVal1 == value) {
          const input = liOptions[i] as HTMLElement;
          document.querySelectorAll('.open .dropdown-menu')[0].scrollTop = input.offsetTop;
          (liOptions[i].getElementsByTagName("div"))[0].classList.add("active");
          return false;
        }
      }
    }
  }

  private findIndex(values, startIndex, char) {
    for (let ii = startIndex; ii < values.length; ii++) {
      let value;
      if (this._toString) {
        value = this._toString(values[ii]);
      } else {
        value = values[ii];
      }
      if (value && value.toLowerCase().startsWith(char)) {
        return ii;
      }
    }
    return -1;
  }

  private find(values, char) {
    let value;
    let index = this.findIndex(values, this.lastIndex + 1, char);
    if (index < 0 && this.lastIndex >= 0) {
      index = this.findIndex(values, 0, char);
    }

    if (index >= 0) {
      this.lastIndex = index;
      return values[index];
    }

    return null;
  }

  private selectDropdownfisrtOption() {
    var eleMake = document.getElementById("selYear");
    var eleChild = eleMake.getElementsByClassName("dropdown-menu");
    if (eleChild != null && eleChild.length > 0) {
      eleChild[0].getElementsByTagName('li')[0].getElementsByTagName('div')[0].classList.add("active");
    }
  }
  //Adobe Analytics changes
  private updatedigitalData(ctgry: string) {
    if (typeof (window["digitalData"]) != "undefined")
      window["digitalData"].page.category.primaryCategory = ctgry;
  }
  determineSearch(isVin: boolean) {
    if (isVin)
      this.updatedigitalData("VIN Search");
    else
      this.updatedigitalData("Make Model Search");
  }
}
function vinTrimming(control: AbstractControl): { [key: string]: any } | null {
  var vin: string = control.value ? control.value : '';
  if (vin.trim().length >= 10 && vin.trim().length <= 17) {
    return null;
  } else {
    return { 'vinTrimming': true };
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate {
    adminUserId: string = '';
    userId: string = '';
    constructor(
        private cookieService: CookieService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const userId: string = this.cookieService.get('userid');
        this.userId = userId;
        this.adminUserId = localStorage.getItem('admin');

        if (this.adminUserId == this.userId) {
            return true;
        }

        return false;
    }
}

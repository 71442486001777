import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { VehicleService } from '../../services/vehicle.service';
import { PriceNewSymbolResult } from '../../models/vinmaster-response';
import { Router } from '@angular/router';
import { PriceNewSearchRequest } from '../../models/vinmaster-request';
import * as fromVehicle from '../../vehicle/state/vehicle.reducer';
import { Store, select } from '@ngrx/store';
import { VehicleActionTypes } from '../../vehicle/state/vehicle.actions';

@Component({
  selector: 'app-price-new',
  templateUrl: './price-new.component.html',
  styleUrls: ['./price-new.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceNewComponent implements OnInit {
  public years: number[] = [];
  public isPriceNewSearchActivated: boolean = false;
  public priceNewSearchForm: FormGroup;
  public priceNewSymbolResult: PriceNewSymbolResult = {symbol:'loading...'};

  priceNewControl = new FormControl('', [Validators.required, Validators.min(0), Validators.pattern("^[0-9\.]*$")]);

  constructor(private formBuilder: FormBuilder,
    private vehicleService: VehicleService,
    private router: Router,
    private store: Store<fromVehicle.State>,
    private cd: ChangeDetectorRef) { 
      this.priceNewSearchForm = this.formBuilder.group({
        year: new FormControl('', [Validators.required]),
        msrp: this.priceNewControl
      });
    }

  ngOnInit() {
    for (let i = 1995; i <= new Date().getFullYear() + 1; i++) {
      this.years.push(i);
    }

    this.years.reverse();

    this.store.pipe(select(fromVehicle.getPriceNewSearchState)).subscribe(newState => {
      this.isPriceNewSearchActivated = newState;
      if(!newState){
        this.priceNewSearchForm.reset();
      }
      this.cd.detectChanges();
    });
  }

  msrpChange() {
    this.cd.detectChanges();
  }

  public onPriceNewYearChange(year: string): void {
    this.priceNewSearchForm.controls.year.setValue(year);
    this.cd.detectChanges();
  }

  public onPriceNewSearchSubmit(priceNewSearchData: PriceNewSearchRequest) {
   /*
    this.store.dispatch({
      type: VehicleActionTypes.HideWelcomeMessage,
      payload: true
    });
    */

    document.getElementById("priceNewSearchModal").click();
    
    this.vehicleService.priceNewSymbolSearch(priceNewSearchData.msrp, priceNewSearchData.year).subscribe(result => {
      this.priceNewSymbolResult = result;
      this.cd.detectChanges();
    });

    this.store.dispatch({
      type: VehicleActionTypes.PriceNewSearch,
      payload: false
    });
    this.priceNewSearchForm.reset();
  }

}

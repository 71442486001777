import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { VehicleService } from 'src/app/services/vehicle.service';
import { VehicleActionTypes, ModelSearchLoad, LoadSuccess, VinSearchLoad } from './vehicle.actions';
import { mergeMap, map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class VehicleEffects {
  constructor(private action$: Actions,
              private router: Router,
              private vehicleService: VehicleService) { }

  
  @Effect()
  modelSearchLoadVehicle$ = this.action$.pipe(
    ofType(VehicleActionTypes.ModelSearchLoad),
    mergeMap((action: ModelSearchLoad) =>
      this.vehicleService.modelSearch(action.payload).pipe(
        map(vehicles => ({ type: VehicleActionTypes.LoadSuccess, payload: vehicles }))
      )
    )
  );

  @Effect()
  vinSearchLoadVehicle$ = this.action$.pipe(
    ofType(VehicleActionTypes.VinSearchLoad),
    mergeMap((action: VinSearchLoad) =>
      this.vehicleService.vinSearch(action.payload).pipe(
        map(vehicles => {
          if(vehicles.length > 0){
            this.router.navigate(['/vehicles']);
            return { type: VehicleActionTypes.LoadSuccess, payload: vehicles };
          }else{
            this.router.navigate(['/vehicles/vinnotfound']);
            return { type: VehicleActionTypes.LoadSuccess, payload: vehicles };
           // return { type: VehicleActionTypes.VinNotFound, payload: true };
          }
        })
      )
    )
  );
}

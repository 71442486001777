import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VehicleDetailComponent } from './vehicle-detail/vehicle-detail.component';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';
import { VehicleNotfoundComponent } from './vehicle-notfound/vehicle-notfound.component';
import { VinNotfoundComponent } from './vin-notfound/vin-notfound.component';


const routes: Routes = [
  {path: 'vehicles', component: VehicleListComponent},
  {path: 'vehicles/vehiclenotfound', component: VehicleNotfoundComponent},
  {path: 'vehicles/vinnotfound', component: VinNotfoundComponent},
  {path: 'vehicles/:id', component: VehicleDetailComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VehicleRoutingModule { }

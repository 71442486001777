import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehicleRoutingModule } from './vehicle-routing.module';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';
import { VehicleDetailComponent } from './vehicle-detail/vehicle-detail.component';
import { VehicleNotfoundComponent } from './vehicle-notfound/vehicle-notfound.component';
import { reducer } from './state/vehicle.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { VehicleEffects } from './state/vehicle.effects';
import { VinNotfoundComponent } from './vin-notfound/vin-notfound.component';

@NgModule({
  declarations: [
    VehicleListComponent,
    VehicleDetailComponent,
    VehicleNotfoundComponent,
    VinNotfoundComponent
  ],
  imports: [
    CommonModule,
    VehicleRoutingModule,
    StoreModule.forFeature('vehicles', reducer),
    EffectsModule.forFeature([VehicleEffects]),
  ]
})
export class VehicleModule { }

export interface State {
  user: any;
}

export function reducer(state, action) {
  switch (action.type) {
    default:
      return state;
  }
}


import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { IProfileLinks } from '../models/profile-links';
import { ProductService } from '../services/product.service';
import { ISO_FOOTER_FILTER_ARGS } from '../shared/constants/app.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

footerLinks = { corporateHome: '', termsAndConditions: '', privacyAndPolicy: '', contactUs: '' };
errorMessage = '';
lastHeight = 0;
footerFilterArgs = ISO_FOOTER_FILTER_ARGS;
userProfileLinks: IProfileLinks[];
currentYear = new Date().getFullYear();
isAuthenticated = false;

constructor(private configService: ConfigService,
            private producService: ProductService) {
    this.configService.getConfigStatus().subscribe(
      _configStatus => {
        if (_configStatus) {
          this.producService.getAllUserProfileLinks(this.configService.getGlobalConfig().megaMenuApiUrl).subscribe(
            _userProfileLinks => {
              this.userProfileLinks = _userProfileLinks;

              _userProfileLinks.forEach(item => {
                if (item.code === 'ISO_HOME') {
                  this.footerLinks.corporateHome = item.url;
                }
                if (item.code === 'TERMS') {
                  this.footerLinks.termsAndConditions = item.url;
                }
                if (item.code === 'POLICY') {
                  this.footerLinks.privacyAndPolicy = item.url;
                }
                if (item.code === 'CONTACT') {
                  this.footerLinks.contactUs = item.url;
                }
              });
            },
            error => this.errorMessage = error
          );
        }
      }
    );
}

}

import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromVehicle from '../state/vehicle.reducer';
import { VehicleActionTypes } from '../state/vehicle.actions';

@Component({
  selector: 'app-vehicle-notfound',
  templateUrl: './vehicle-notfound.component.html',
  styleUrls: ['./vehicle-notfound.component.css']
})
export class VehicleNotfoundComponent implements OnInit {
  @Output() 'event-action-trigger' = new EventEmitter();
  constructor(private router: Router, private store: Store<fromVehicle.State>) { }
  
  ngOnInit() {
  }

  public goToHome() {
    this.store.dispatch({
      type: VehicleActionTypes.PriceNewSearch,
      payload: false
    });
    this.router.navigate(['/']);
  }

  public activatePriceNewSearch(){
    window["digitalData"].page.pageInfo.pageID = "Price New Page";
    this["event-action-trigger"].emit();
    this.store.dispatch({
      type: VehicleActionTypes.PriceNewSearch,
      payload: true
    });
  }

}

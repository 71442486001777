import { Injectable } from '@angular/core';
import { Logger } from './logger';
import { LoggerRegistrarService } from './logger-registrar.service';

enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Off = 5
}

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private level: LogLevel = LogLevel.All;
  private loggers: Logger[];

  constructor(private loggerRegistar: LoggerRegistrarService) {
    this.loggerRegistar.getLoggerRegistrationStatus().subscribe(status => {
      if (status) {
        this.loggers = this.loggerRegistar.getRegisteredLoggers();
      }
    });
  }

  debug(msg: any) {
    this.log(LogLevel.Debug, msg);
  }

  info(msg: any) {
    this.log(LogLevel.Info, msg);
  }

  warn(msg: any) {
    this.log(LogLevel.Warn, msg);
  }

  error(msg: any) {
    this.log(LogLevel.Error, msg);
  }

  private log(level: LogLevel, msg: any): void {
    if (this.shouldLog(level)) {
      for (const logger of this.loggers) {
        logger.log('[' + LogLevel[level] + '] ' + new Date().toLocaleString() + ': ' + JSON.stringify(msg));
      }
    }
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;
    if ((level >= this.level &&
      level !== LogLevel.Off) ||
      this.level === LogLevel.All) {
      ret = true;
    }
    return ret;
  }
}



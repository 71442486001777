import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: '',
  styleUrls: ['./app.component.css']
})
export class BlankComponent {
  constructor() {

  }
}

import { Action } from '@ngrx/store';
import { VehicleCount } from 'src/app/models/vehicle-count';

export enum AdminActionTypes {
    LOAD_VEHICLE_COUNTS = '[VehicleCount] Load VehicleCounts',
    LOAD_VEHICLE_COUNTS_SUCCESS = '[VehicleCount] Load VehicleCounts Success',
    LOAD_VEHICLE_COUNTS_FAIL = '[VehicleCount] Load VehicleCounts Fail'
}

export class LoadVehicleCount implements Action {
  readonly type = AdminActionTypes.LOAD_VEHICLE_COUNTS; 
}

export class LoadVehicleCountsSuccess implements Action {
    readonly type = AdminActionTypes.LOAD_VEHICLE_COUNTS_SUCCESS;
    constructor(public payload: VehicleCount[]) { }
  }

  export class LoadVehicleCountsFail implements Action {
    readonly type = AdminActionTypes.LOAD_VEHICLE_COUNTS_FAIL;
    constructor(public payload: string) { }
  }

  export type AdminActions = LoadVehicleCount|LoadVehicleCountsSuccess|LoadVehicleCountsFail;
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  constructor(@Inject(DOCUMENT) private document: Document) { }
  private loadCustomerName: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private script: any
  public loadJsScript(renderer: Renderer2, src: string): HTMLScriptElement {
    this.script = renderer.createElement('script');
    this.script.type = 'text/javascript';
    this.script.src = src;
    renderer.appendChild(this.document.body, this.script);
    return this.script;
  }

  public removeScript(renderer: Renderer2, src: string) {
    renderer.removeChild(this.document.body, this.script);
  }

  getCustomerNameObs(): Observable<boolean> {
    return this.loadCustomerName.asObservable();
  }


  setCustomerNameObs() {
    this.loadCustomerName.next(true);
  }
}

import { Component, OnInit, EventEmitter,Output } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromVehicle from '../state/vehicle.reducer';
import { VehicleActionTypes } from '../state/vehicle.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vin-notfound',
  templateUrl: './vin-notfound.component.html',
  styleUrls: ['./vin-notfound.component.css']
})
export class VinNotfoundComponent implements OnInit {
  message: any;
  @Output() 'event-action-trigger' = new EventEmitter();
  constructor(private store: Store<fromVehicle.State>, private router: Router) { }

  ngOnInit() {
    this.store.pipe(select(fromVehicle.getVehicleListState)).subscribe((newData: any) => {    
      this.message = newData;
    });
  }

  public goToMakeModelSearch() {
    this.store.dispatch({
      type: VehicleActionTypes.PriceNewSearch,
      payload: false
    });

    this.store.dispatch({
      type: VehicleActionTypes.ResetSearchControl,
      payload: true
    });
    this.router.navigate(['/']);
  }

  public activatePriceNewSearch() {
   window["digitalData"].page.pageInfo.pageID = "Price New Page";
   this["event-action-trigger"].emit();
    this.store.dispatch({
      type: VehicleActionTypes.PriceNewSearch,
      payload: true
    });
  }

}

import { Action } from '@ngrx/store';
import { PDResult } from 'src/app/models/vinmaster-response';
import { ModelSearchRequest, VinSearchRequest } from 'src/app/models/vinmaster-request';

export enum VehicleActionTypes {
  SetCurrentVehicle = '[Vehicle] Set Current Vehicle',
  ModelSearchLoad = '[Vehicle] Model Search Load',
  VinSearchLoad = '[Vehicle] Vin Search Load',
  LoadSuccess = '[Vehicle] Load Success',
  LoadFail = '[Vehicle] Load Fail',
  //HideWelcomeMessage = '[App] Hide Welcome Message',
  VinNotFound = '[Vehicle] Vin Not Found',
  PriceNewSearch = '[Vehicle] Activate Price New',
  ResetSearchControl = '[App] Reset Search Control'
}

export class SetCurrentVehicle implements Action {
  readonly type: VehicleActionTypes.SetCurrentVehicle;
  constructor(public payload: PDResult) { }
}
export class ModelSearchLoad implements Action {
  readonly type: VehicleActionTypes.ModelSearchLoad;
  constructor(public payload: ModelSearchRequest) { }
}

export class VinSearchLoad implements Action {
  readonly type: VehicleActionTypes.VinSearchLoad;
  constructor(public payload: VinSearchRequest) { }
}

export class LoadSuccess implements Action {
  readonly type: VehicleActionTypes.LoadSuccess;
  constructor(public payload: PDResult[]|any) { }
}

export class LoadFail implements Action {
  readonly type: VehicleActionTypes.LoadFail;
  constructor(public payload: string) { }
}
/*
export class HideWelcomeMessage implements Action {
  readonly type: VehicleActionTypes.HideWelcomeMessage;
  constructor(public payload: boolean) { }
}
*/

export class VinNotFound implements Action {
  readonly type: VehicleActionTypes.VinNotFound;
  constructor(public payload: boolean) { }
}

export class PriceNewSearch implements Action {
  readonly type: VehicleActionTypes.PriceNewSearch;
  constructor(public payload: boolean) { }
}

export class ResetSearchControl implements Action {
  readonly type: VehicleActionTypes.ResetSearchControl;
  constructor(public payload: boolean) { }
}

export type VehicleActions = SetCurrentVehicle
  | ModelSearchLoad
  | VinSearchLoad
  | LoadSuccess
  | LoadFail
  //| HideWelcomeMessage
  | VinNotFound
  | PriceNewSearch
  | ResetSearchControl;

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userProfileFilter'
})
export class UserProfileFilterPipe implements PipeTransform {

  transform(items: any[], filter: string[]): any {
    if (!items || !filter) {
        return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    const res = items.filter(item => filter.indexOf(item.code) !== -1);
    res.sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0));
    return res;
}

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { catchError, map } from 'rxjs/operators';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { VinMasterResponse, MakeResult, ModelNameResult, BodyStyleResult, PDResult, PriceNewSymbolResult } from '../models/vinmaster-response';
import { LogService } from '../log/log.service';
import { ModelSearchRequest, VinSearchRequest } from '../models/vinmaster-request';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  private aslApiUrl: string;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      Accept: 'application/json'
    })
  };

  constructor(private http: HttpClient,
              private configService: ConfigService,
              private logService: LogService) {
    this.configService.getConfigStatus().subscribe(
      configStatus => {
        if (configStatus) {
          const appConfig = this.configService.getGlobalConfig();
          this.aslApiUrl = appConfig.aslApiUrl;
        }
      }
    );
  }

  public vinSearch(vinSearch: VinSearchRequest): Observable<PDResult[]|any> {
    const requestBody = { vin: vinSearch.vin, year: vinSearch.year };
    return this.vinMasterApiCall<PDResult[]|any>(requestBody, 'vinsearch');
  }

  public modelSearch(modelSearch: ModelSearchRequest): Observable<PDResult[]> {
    const requestBody = { make: modelSearch.make, model: modelSearch.model, year: modelSearch.year, bodyStyle: modelSearch.bodyStyle };
    return this.vinMasterApiCall<PDResult[]>(requestBody, 'modelsearch');
  }

  public priceNewSymbolSearch(msrp: string, year: string): Observable<PriceNewSymbolResult> {
    const requestBody = { year, msrp };
    return this.vinMasterApiCall<PriceNewSymbolResult>(requestBody, 'pricenewsymbol');
  }

  public makeSearch(year: string): Observable<MakeResult[]> {
    const requestBody = { year };
    return this.vinMasterApiCall<MakeResult[]>(requestBody, 'makesearch');
  }

  public modelNameSearch(make: string, year: string): Observable<ModelNameResult[]> {
    const requestBody = { year, make };
    return this.vinMasterApiCall<ModelNameResult[]>(requestBody, 'modelnamesearch');
  }

  public bodyStyleSearch(model: string, make: string, year: string): Observable<BodyStyleResult[]> {
    const requestBody = { year, make, model };
    return this.vinMasterApiCall<BodyStyleResult[]>(requestBody, 'bodystylesearch');
  }

  private vinMasterApiCall<T>(requestBody: any, uri: string): Observable<T> {
    return this.http.post<VinMasterResponse<T>>(`${this.aslApiUrl}/${uri}`, requestBody, this.httpOptions).pipe(
      map(x => x.body),
      catchError(this.handleError)
    );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
        errorMessage = `An error occurred: ${err.error.message}`;
    } else {
        errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    this.logService.error(errorMessage);
    return throwError(errorMessage);
  }
}

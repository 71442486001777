import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { AdminActionTypes } from './admin.actions';
import * as adminActions from './admin.actions';
import { Observable, of } from "rxjs";
import { Action } from "@ngrx/store";
import { map, mergeMap, catchError } from "rxjs/operators";
import { VehicleCount } from 'src/app/models/vehicle-count';


@Injectable()
export class AdminEffects {
    constructor(private action$: Actions,
        private router: Router,
        private adminService: AdminService) { }

    
    @Effect()
    loadVehicleCounts$: Observable<Action> = this.action$.pipe(
        ofType<adminActions.LoadVehicleCount>(
            AdminActionTypes.LOAD_VEHICLE_COUNTS
        ),
        mergeMap((action: adminActions.LoadVehicleCount) =>
            this.adminService.getAllVehicleCount().pipe(
                map(
                    (vehicleList: VehicleCount[]) =>
                        new adminActions.LoadVehicleCountsSuccess(vehicleList)
                ),
                catchError(err => of(new adminActions.LoadVehicleCountsFail(err)))
            )
        )
    );
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PDResult } from 'src/app/models/vinmaster-response';
import { Store, select, resultMemoize } from '@ngrx/store';
import * as fromVehicle from '../state/vehicle.reducer';
import * as vehicleActions from '../state/vehicle.actions';


@Component({
  selector: 'app-vehicle-list',
  templateUrl: './vehicle-list.component.html',
  styleUrls: ['./vehicle-list.component.css']
})
export class VehicleListComponent implements OnInit {

  public vehicles: PDResult[];  
  @Output()  'event-action-trigger' = new EventEmitter();
 
  constructor(private store: Store<fromVehicle.State>) { }

  ngOnInit() {
    this.store.pipe(select(fromVehicle.getVehicleListState)).subscribe(newData => {
      if (newData instanceof Array) {
        this.vehicles = newData;
      } else {
        this.vehicles = [];
      }
    });
  }

  public onRowClick(vehicle: PDResult): void {
    window["digitalData"].page.pageInfo.pageID = "Vehicle Detail Page";
    this["event-action-trigger"].emit();

    this.store.dispatch({
      type: vehicleActions.VehicleActionTypes.SetCurrentVehicle,
      payload: vehicle
    });
    if (document.getElementById("vdmbody") != null && document.getElementById("vdmbody") != undefined) {
      setTimeout(function () { document.getElementById("vdmbody").scrollTop = 0; }, 100);
    }
  }
  addComma(transmissionInfo: string, otherInfo: string, modelSeriesInfo: string, restraintInfo: string, bodyInfo: string, engineInfo: string): string {
    var result = '';

    if (transmissionInfo && transmissionInfo.trim()) {
      result = result + transmissionInfo + ', ';
    }

    if (otherInfo && otherInfo.trim()) {
      result = result + otherInfo + ', ';
    }

    if (modelSeriesInfo && modelSeriesInfo.trim()) {
      result = result + modelSeriesInfo + ', ';
    }

    if (restraintInfo && restraintInfo.trim()) {
      result = result + restraintInfo + ', ';
    }

    if (bodyInfo && bodyInfo.trim()) {
      result = result + bodyInfo + ', ';
    }

    if (engineInfo && engineInfo.trim()) {
      result = result + engineInfo + ', ';
    }

    result = result.trim();
    return result.substring(0, result.length - 1);
  }
  hideMessge(): boolean {    
    if (this.vehicles.length > 0 && (this.vehicles[0].message.search('VIN appears to be invalid') != -1)) {
      return true;
    }
    else {
      return false;
    }
  }
  showMsg() {   
    //return this.vehicles[0].message;
    const msg = 'Check-digit failure; VIN may be invalid.'; 
    window["digitalData"].page.category.subCategory1 = msg;
    return msg;
  }
}

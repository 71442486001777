import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { LogService } from '../log/log.service';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { VehicleCount } from 'src/app/models/vehicle-count';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private adminApiUrl: string;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    })
  };

  constructor(private http: HttpClient,
    private configService: ConfigService,
    private logService: LogService) {
    
    this.configService.getConfigStatus().subscribe(
      configStatus => {
        if (configStatus) {
          const appConfig = this.configService.getGlobalConfig();
          this.adminApiUrl = appConfig.adminApiUrl;
        }
      }
    );
  }

  getAllVehicleCount(): Observable<VehicleCount[]> {    
    return this.http.get<VehicleCount[]>(`${this.adminApiUrl}/RecordByCount`).pipe(
      catchError(this.handleError)
    );
  }
  getAdminUser(adminUrl: string): Observable<any> {    
    return this.http.get<any>(`${adminUrl}/GetAdminUserId`).pipe(
      catchError(this.handleError)
    );
  }

  getUserEligibility(adminUrl: string, token: string,): Observable<any> {    
    return this.http.get<any>(`${adminUrl}/GetUserEligibility?isoToken=${token}`).pipe(
      catchError(this.handleError)
    );
  }
  
  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    this.logService.error(errorMessage);
    return throwError(errorMessage);
  }

}

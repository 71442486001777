import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IProfileLinks } from '../models/profile-links';
import { IProduct } from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) {
  }

  getAllUserProfileLinks(megaMenuApiUrl: string): Observable<IProfileLinks[]> {
      return this.http.get<IProfileLinks[]>(`${megaMenuApiUrl}/alllprofileinks`).pipe(
        catchError(this.handleError)
      );
  }

  getEntitledUserProfileLinks(userId: string, token: string, megaMenuApiUrl: string): Observable<IProfileLinks[]> {
      return this.http.get<IProfileLinks[]>(`${megaMenuApiUrl}/userprofileinks?userId=${userId}&isoToken=${token}`).pipe(
        catchError(this.handleError)
      );
  }

  getUserProducts(userId: string, token: string, megaMenuApiUrl: string): Observable<IProduct[]> {
      return this.http.get<IProduct[]>(`${megaMenuApiUrl}/userproducts?userId=${userId}&isoToken=${token}`).pipe(
        catchError(this.handleError)
      );
  }

  getAllProducts(megaMenuApiUrl: string) {
      return this.http.get(`${megaMenuApiUrl}/allproducts`).pipe(
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
      let errorMessage = '';
      if (err.error instanceof ErrorEvent) {
          errorMessage = `An error occurred: ${err.error.message}`;
      } else {
          errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
      }
      console.error(errorMessage);
      return throwError(errorMessage);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IProfileLinks } from '../models/profile-links';
import { IProduct } from '../models/product';
import { OAuthService } from 'angular-oauth2-oidc';
import { USER_PROFILE_FILTER_ARGS, ISO_EDUCATION_FILTER_ARGS, PRIMARY_HOST_DOMAIN } from '../shared/constants/app.constants';
import { LoginService } from '../services/login.service';
import { ProductService } from '../services/product.service';
import { ConfigService } from '../services/config.service';
import { AdminService } from 'src/app/services/admin.service';
import { ScriptService } from '../services/script.service';

const CUSTOMER_NAME = 'customername';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  objectKeys = Object.keys;

  userProfileLinks: IProfileLinks[];  // user drop-down menu data set
  userISOProducts: IProduct[]; // megamenu data set
  allISOProducts: IProduct[];  // megamenu data set
  categoryISOProducts: any; // megamenu data set

  userProfileFilterArgs = USER_PROFILE_FILTER_ARGS;
  isoEducationFilterArgs = ISO_EDUCATION_FILTER_ARGS;

  errorMessage: any = '';
  hasAllProducts = '';
  expandAllProductsDisabled = '';
  dropdownClass = '';
  megaMenuApiUrl = '';
  redirectUrl = '';
  userGuideUrl: string = '';
  homePageApiUrl: string = '';
  adminPageApiUrl: string = '';

  customerSupportUrl = '';
  isAuthenticated = false;
  hoverMegamenu = 'allprod';
  adminUserId: string = '';
  loginUserId: string = '';
  isShow: boolean = false;
  customerName = '';

  constructor(private productService: ProductService,
    private cookieService: CookieService,
    private configService: ConfigService,
    private oauthService: OAuthService,
    private loginService: LoginService,
    private adminService: AdminService,
    private scriptService: ScriptService) {

    this.allISOProducts = [];
    this.categoryISOProducts = {};

    const userId: string = this.cookieService.get('userid');
    //const userId = 'aaauser';
    //const ticket = '58251F0AX93DEX4039X8CA4XF613C46DF2C0';
    const ticket: string = this.cookieService.get('ticket');
    this.loginUserId = userId;

    this.configService.getConfigStatus().subscribe(
      configStatus => {
        if (configStatus) {
          const appConfig = this.configService.getGlobalConfig();
          this.megaMenuApiUrl = appConfig.megaMenuApiUrl;
          this.redirectUrl = appConfig.redirectUrl;
          this.userGuideUrl = appConfig.userGuideUrl;
          this.homePageApiUrl = appConfig.homePageApiUrl;
          this.adminPageApiUrl = appConfig.adminApiUrl;

          this.customerSupportUrl = appConfig.veriskCustomerSupport;

          this.adminService.getUserEligibility(this.adminPageApiUrl, ticket).subscribe(
            data => {
              this.loginService.setEligibilityStatus(data);
            },
            error => {
              console.log(error);
            });

          this.adminService.getAdminUser(this.adminPageApiUrl).subscribe(
            data => {
              this.adminUserId = data;
              localStorage.setItem('admin', this.adminUserId);
              if (this.adminUserId == this.loginUserId) {
                this.isShow = true;
              }
            },
            error => {
              console.log(error);
            });

          this.productService.getAllProducts(this.megaMenuApiUrl).subscribe(
            data => {
              // populate category products
              data['categories'].forEach(c => {
                this.categoryISOProducts[c['title']] = c['products'];
              });
              // populate all products
              data['products'].forEach(p => {
                const item: IProduct = {
                  id: p['id'],
                  title: p['title'],
                  url: p['url'],
                  isEligible: p['isEligible']
                };
                this.allISOProducts.push(item);
              });

              if (ticket && userId) {
                this.productService.getEntitledUserProfileLinks(userId, ticket, this.megaMenuApiUrl).subscribe(
                  userProfileLinks => {
                    // decorate received url for iso education and append ticket and userid to the url
                    userProfileLinks.forEach(up => {
                      if (up.linkFor === 'ISO Education') {
                        up.url += '?ticket=' + ticket + '|' + userId;
                      }
                    });

                    this.userProfileLinks = userProfileLinks;
                  },
                  error => this.errorMessage = error
                );

                this.productService.getUserProducts(userId, ticket, this.megaMenuApiUrl).subscribe(
                  userProducts => {
                    // populate user products
                    this.userISOProducts = userProducts;
                    this.hoverMegamenu = 'myprod';
                    userProducts.forEach(p => {
                      // reset eligible products in all products
                      const index = this.allISOProducts.findIndex((element) => {
                        return element.title === p.title;
                      });
                      if (index > -1) {
                        this.allISOProducts[index].isEligible = true;
                      }
                      // reset eligible products in category products
                      // tslint:disable-next-line: forin
                      for (const key in this.categoryISOProducts) {
                        const i = this.categoryISOProducts[key].findIndex((element) => {
                          return element.title === p.title;
                        });
                        if (i > -1) {
                          this.categoryISOProducts[key][i].isEligible = true;
                        }
                      }
                    });
                  },
                  error => this.errorMessage = error
                );
              }
            }
          );
        }
      },
      error => this.errorMessage = error
    );
  }

  ngOnInit(): void {
    this.loginService.getLoginStatus().subscribe(
      status => this.isAuthenticated = status
    );
    var loadCustomer$ = this.scriptService.getCustomerNameObs();
    loadCustomer$.subscribe(x => {
      if (x) {
        this.customerName = this.cookieService.get(CUSTOMER_NAME);
      }
    })

    //this.adminService.getAdminUser('http://localhost:60418/api/Admin').subscribe(
    // this.adminService.getAdminUser(this.adminPageApiUrl).subscribe(
    //   data => {
    //       this.adminUserId = data;
    //       localStorage.setItem('admin', this.adminUserId);
    //       if (this.adminUserId == this.loginUserId)
    //       {
    //       this.isShow = true;
    //       }
    //   },
    //   error => {
    //       console.log(error);
    //   });

  }
  public login(): void {
    this.oauthService.initImplicitFlow();
  }

  public signout(): void {
    this.cookieService.deleteAll('/', PRIMARY_HOST_DOMAIN);
    this.loginService.setLoginStatus(false);
    this.oauthService.logOut();
  }

  hoverIn(category: string): void {
    this.hoverMegamenu = category;
  }
}

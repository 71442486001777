import { PDResult } from 'src/app/models/vinmaster-response';
import * as fromRoot from 'src/app/state/app.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VehicleActionTypes, VehicleActions } from './vehicle.actions';

export interface State extends fromRoot.State {
  vehicles: VehicleState;
}

export interface VehicleState {
  selectedVehicle: PDResult;
  vehicles: []|any;
  hideWelcomeMsg: boolean;
  vinNotFound: boolean;
  activatePriceNew: boolean;
  resetSearchControl: boolean;
}

const initialState: VehicleState = {
  selectedVehicle: null,
  vehicles: [],
  hideWelcomeMsg: false,
  vinNotFound: false,
  activatePriceNew: false,
  resetSearchControl: false,
};
const getVehicleFeatureState = createFeatureSelector<VehicleState>('vehicles');

export const getSelectedVehicleState = createSelector(
  getVehicleFeatureState,
  state => state.selectedVehicle
);

export const getVehicleListState = createSelector(
  getVehicleFeatureState,
  state => state.vehicles
);

/*
export const getHideWelcomeMsgState = createSelector(
  getVehicleFeatureState,
  state => state.hideWelcomeMsg
);
*/

export const getVinNotFoundState = createSelector(
  getVehicleFeatureState,
  state => state.vinNotFound
);

export const getPriceNewSearchState = createSelector(
  getVehicleFeatureState,
  state => state.activatePriceNew
);

export const getResetSearchontrolState = createSelector(
  getVehicleFeatureState,
  state => state.resetSearchControl
);

export function reducer(state: VehicleState = initialState, action: VehicleActions) {
  switch (action.type) {
    case VehicleActionTypes.SetCurrentVehicle:
      return {
        ...state,
        selectedVehicle: action.payload
      };
    case VehicleActionTypes.LoadSuccess:
      //Adobe Tracking changes
      if(action.payload != "undefined" && action.payload.message != "undefined")
        window["digitalData"].page.category.subCategory1 = action.payload.message;
      return {
        ...state,
        vehicles: action.payload
      };
      /*
    case VehicleActionTypes.HideWelcomeMessage:
      return {
        ...state,
        hideWelcomeMsg: action.payload
      };
      */
    case VehicleActionTypes.VinNotFound:
      return {
        ...state,
        vinNotFound: action.payload
      };
    case VehicleActionTypes.PriceNewSearch:
      return {
        ...state,
        activatePriceNew: action.payload
      };
    case VehicleActionTypes.ResetSearchControl:
      return {
        ...state,
        resetSearchControl: action.payload
      };
    default:
      return state;
  }
}

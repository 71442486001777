import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BlankComponent } from './blank.component';


const routes: Routes = [
  {path: 'index.html', component: BlankComponent},
  {path: '', redirectTo: 'index.html', pathMatch: 'full'},
  {path: '**', component: BlankComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

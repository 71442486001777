import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { PDResult } from 'src/app/models/vinmaster-response';
import { Store, select } from '@ngrx/store';
import * as fromVehicle from '../state/vehicle.reducer';

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.css']
})
export class VehicleDetailComponent implements OnInit {

  public vehicle: PDResult;
  @Output() 'event-action-trigger' = new EventEmitter();
  constructor(private store: Store<fromVehicle.State>) { }

  ngOnInit() {
    this.store.pipe(select(fromVehicle.getSelectedVehicleState)).subscribe(selectedVehicle => {
      this.vehicle = selectedVehicle;
    });
  }
  addComma(transmissionInfo: string, otherInfo: string, modelSeriesInfo: string, restraintInfo: string, bodyInfo: string, engineInfo: string): string {
    var result = '';

    if (transmissionInfo && transmissionInfo.trim()) {
      result = result + transmissionInfo + ', ';
    }

    if (otherInfo && otherInfo.trim()) {
      result = result + otherInfo + ', ';
    }

    if (modelSeriesInfo && modelSeriesInfo.trim()) {
      result = result + modelSeriesInfo + ', ';
    }

    if (restraintInfo && restraintInfo.trim()) {
      result = result + restraintInfo + ', ';
    }

    if (bodyInfo && bodyInfo.trim()) {
      result = result + bodyInfo + ', ';
    }

    if (engineInfo && engineInfo.trim()) {
      result = result + engineInfo + ', ';
    }
    
    result = result.trim();
    return result.substring(0, result.length - 1);
  }

  updatedigitalData(action: string)
  {
    if (action == "lpmpopen")
    window["digitalData"].page.pageInfo.pageID = "Vehicle LPMP Page";
    else if (action == "lpmpclose")
    window["digitalData"].page.pageInfo.pageID = "Vehicle Detail Page";
    else
    window["digitalData"].page.pageInfo.pageID = "Vehicle List Page";
    this["event-action-trigger"].emit();
  }
}

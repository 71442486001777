import { Component, OnInit, EventEmitter, Output, Renderer2, OnDestroy } from '@angular/core';
import { LoginService } from './services/login.service';
import * as fromVehicle from './vehicle/state/vehicle.reducer';
import { Store} from '@ngrx/store';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { PRIMARY_HOST_DOMAIN } from './shared/constants/app.constants';
import { OAuthService, AuthConfig, JwksValidationHandler } from 'angular-oauth2-oidc';
import { ConfigService } from '../app/services/config.service';
import { ScriptService } from './services/script.service';
declare function loadCustomerSelectionJS(): any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  isAuthenticated: boolean = false;
  isEligible: boolean;
  routerUrl: string;
  //adobe analytics custom event
  pageID: string;
  digitalData: any;
  @Output() 'event-action-trigger' = new EventEmitter();  
  authConfig: AuthConfig = {};
  errorMessage: any;
  scriptPath = '';

  constructor(private loginService: LoginService,
    private store: Store<fromVehicle.State>,
    private cookieService: CookieService,
    private configService: ConfigService,
    private oauthService: OAuthService,
    private scriptService: ScriptService,
    private renderer: Renderer2,
    private _router: Router) {   
    this._router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });

    this.configService.getConfigStatus().subscribe(
      _configStatus => {
        if (_configStatus) {
          const _appConfig = this.configService.getGlobalConfig();
          this.authConfig.issuer = _appConfig.identityUrl;
          this.authConfig.redirectUri = _appConfig.redirectUrl;
          this.authConfig.scope = 'openid';
          this.authConfig.logoutUrl = _appConfig.identityUrl + '/connect/endsession';
          this.authConfig.clientId = _appConfig.clientId;
          this.authConfig.redirectUri = _appConfig.redirectUrl;
          this.configureWithNewConfigApi();
          this.setAuthenticationStatus();
          this.scriptPath = _appConfig.customerSelectionScript
          const scriptElement = this.scriptService.loadJsScript(this.renderer, this.scriptPath);
          scriptElement.onload = () => {
            loadCustomerSelectionJS();
            this.scriptService.setCustomerNameObs();
          }
          scriptElement.onerror = () => {
            console.log('Could not load the customer selection script!');
          }
        }
      },
      error => this.errorMessage = <any>error
    );

    this.routerUrl = this._router.url;

    this.loginService.getLoginStatus().subscribe(loginStatus => {
      this.isAuthenticated = loginStatus;
    });
  }

  private configureWithNewConfigApi() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
  setAuthenticationStatus() {
    this.loginService.getLoginStatus().subscribe(
      status => {
        this.isAuthenticated = status;
      }
    );

    this.loginService.getEligibilityStatus().subscribe(
      status => {
        this.isEligible = status;
      }
    );
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.scriptService.removeScript(this.renderer, this.scriptPath);
  }

  checkRouterEvent(routerEvent: Event): void {

    if (routerEvent instanceof NavigationStart) {
      //adobe-analytics to populate page ID and form Datalayer
      this.populatePageFormData(routerEvent.url);
    }

    if (routerEvent instanceof NavigationEnd) {
      if (this.cookieService.get('ticket')) {
        if (this.oauthService.getIdToken()) {
          this.loginService.setLoginStatus(true);
        } else if (!new RegExp('#id_token=.+').test(location.hash)) {
          this.loginService.setLoginStatus(false);
          this.oauthService.initImplicitFlow();
        }
      }
      else {
        if (this.oauthService.getIdToken()) {
          this.cookieService.deleteAll('/', PRIMARY_HOST_DOMAIN);
          this.loginService.setLoginStatus(false);
          this.oauthService.logoutUrl = JSON.parse(sessionStorage.getItem('id_token_claims_obj')).iss + '/connect/endsession';
          // 'https://idt.iso.com/identity/connect/endsession'
          this.oauthService.logOut();
        } else {
          this.oauthService.initImplicitFlow();
        }
      }
    }   
  }
  //adobe analytics form datalayer changes
  private populatePageFormData(url: string): void {
    let dData = window["digitalData"];
    let pctgry = "Make Model Search";
    if (url == '/' || url == '/index.html') this.pageID = 'ASL Home Page';
    if (url == '/vehicles') this.pageID = 'Vehicles List Page';
    if (url == '/vehicles/vehiclenotfound') this.pageID = 'Vehicles Not Found Page';
    if (url == '/vehicles/vinnotfound') this.pageID = 'VIN Not Found Page';
    //changes to track Make/Model or VIN serach under category
    if (dData != null || typeof (dData) != "undefined") pctgry = dData.page.category.primaryCategory;
    if (typeof (this.pageID) != "undefined") {
      this.formDataLayer(this.pageID, url, pctgry); // Call adobe-analytics method only if page id is defined.
      this["event-action-trigger"].emit();
    }
  }
  private formDataLayer(pageID: string, url: string, pctgry: string): void {
    this.digitalData = {
      page:
      {
        pageInfo: {
          pageID: pageID,
          pageURL: "",
          pagePath: url,
          lob: "PA", //Personal Auto
          productname: document.title
        },
        category: {
          primaryCategory: pctgry,
          subCategory1: ""
        },
        filters: {
          class: "",
          state: "",
          coverage: "",
          year: ""
        },
        user: {
          userName: this.cookieService.get('userid'),
          companyID: this.cookieService.get('cstid'),
          companyName: this.cookieService.get('companyName')
        }
      }
    };
    window["digitalData"] = this.digitalData;
  }
}

import { Component, OnInit } from '@angular/core';
import * as fromVehicleCount from './state/admin.reducer';
import * as adminActions from "./state/admin.actions";
import { Store, select } from '@ngrx/store';
import { VehicleCount } from 'src/app/models/vehicle-count';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  vehicleList$: Observable<VehicleCount[]>;
  V1: Observable<VehicleCount[]>;
  V2: Observable<VehicleCount[]>;
  V3: Observable<VehicleCount[]>;
  V4: Observable<VehicleCount[]>;
  V5: Observable<VehicleCount[]>;
  V6: Observable<VehicleCount[]>;

  error$: Observable<String>;

  constructor(private store: Store<fromVehicleCount.AppState>) { }

  ngOnInit() {
    this.store.dispatch(new adminActions.LoadVehicleCount());
    this.vehicleList$ = this.store.pipe(select(fromVehicleCount.getVehicleListCountSuccess));
    this.error$ = this.store.pipe(select(fromVehicleCount.getVehicleCountFail));

    this.V1 = this.vehicleList$.pipe(
      map(arr =>
        arr.filter(r => r.dataType == 'PD–Standard')
          .sort((a, b) => a.modelYear.localeCompare(b.modelYear))
          .sort((a, b) => a.total - b.total)
      )
    );

    this.V2 = this.vehicleList$.pipe(
      map(arr =>
        arr.filter(r => r.dataType == 'PD – Interim')
          .sort((a, b) => a.modelYear.localeCompare(b.modelYear))
          .sort((a, b) => a.total - b.total)
      )
    );

    this.V3 = this.vehicleList$.pipe(
      map(arr =>
        arr.filter(r => r.dataType == 'Light Trucks')
          .sort((a, b) => a.modelYear.localeCompare(b.modelYear))
          .sort((a, b) => a.total - b.total)
      )
    );

    this.V4 = this.vehicleList$.pipe(
      map(arr =>
        arr.filter(r => r.dataType == 'LPMP-Interim')
          .sort((a, b) => a.modelYear.localeCompare(b.modelYear))
          .sort((a, b) => a.total - b.total)
      )
    );

    this.V5 = this.vehicleList$.pipe(
      map(arr =>
        arr.filter(r => r.dataType == 'LPMP-Standard')
          .sort((a, b) => a.modelYear.localeCompare(b.modelYear))
          .sort((a, b) => a.total - b.total)
      )
    );

    this.V6 = this.vehicleList$.pipe(
      map(arr =>
        arr.filter(r => r.dataType == 'ADAS')
          .sort((a, b) => a.modelYear.localeCompare(b.modelYear))
          .sort((a, b) => a.total - b.total)
      )
    );
  }

  getPDStandard() {
    return this.V1.pipe(
      map(arr => arr[0].total)
    );
  }

  getPDInterim() {
    return this.V2.pipe(map(arr => { return arr.length > 0 ? arr[0].total : null }))

  }

  getLightTrucks() {
    return this.V3.pipe(map(arr => { return arr.length > 0 ? arr[0].total : null }))
  }

  getLPMPInterim() {
    return this.V4.pipe(map(arr => { return arr.length > 0 ? arr[0].total : null }))
  }
  getLPMPStandard() {
    return this.V5.pipe(map(arr => { return arr.length > 0 ? arr[0].total : null }))
  }

  getADAS() {
    return this.V6.pipe(map(arr => { return arr.length > 0 ? arr[0].total : null }))
  }
}



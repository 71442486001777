import * as fromRoot from 'src/app/state/app.reducer';
import { VehicleCount } from 'src/app/models/vehicle-count';
import { createFeatureSelector, createSelector, State } from '@ngrx/store';
import { AdminActionTypes, AdminActions } from './admin.actions';

export interface AdminState {
    vehicleCounts:boolean;
    vehicleCountsSuccess: VehicleCount[];
    vehicleCountsFail: boolean;
    error: string;
}

export interface AppState extends fromRoot.State {
    vehicleCounts: AdminState;
}

export const initialState: AdminState = {
    vehicleCounts:false,
    vehicleCountsSuccess: [],
    vehicleCountsFail: false,
    error: ""
};

export function AdminReducer(
    state = initialState,
    action: AdminActions
): AdminState {
    switch (action.type) {
        case AdminActionTypes.LOAD_VEHICLE_COUNTS:
            return {
                ...state,
                vehicleCounts:true,
                vehicleCountsSuccess: [],
                vehicleCountsFail: false
            };

        case AdminActionTypes.LOAD_VEHICLE_COUNTS_SUCCESS:
            return {
                ...state,
                vehicleCounts:false,
                vehicleCountsSuccess: action.payload,
                vehicleCountsFail: false
            };

        case AdminActionTypes.LOAD_VEHICLE_COUNTS_FAIL:
            return {
                ...state,
                vehicleCounts:false,
                vehicleCountsSuccess: [],
                vehicleCountsFail: true,
                error: action.payload
            };

        default:
            {
                return state;
            }
    }
}

const getAdminFeatureState = createFeatureSelector<AdminState>("vehicleCounts");

export const getVehicleListCount = createSelector(
    getAdminFeatureState,
    (state:AdminState) => state.vehicleCounts
);

export const getVehicleListCountSuccess = createSelector(
    getAdminFeatureState,
    (state:AdminState) => state.vehicleCountsSuccess
);

export const getVehicleCountFail = createSelector(
    getAdminFeatureState,
    (state:AdminState) => state.error
);



import { Injectable } from '@angular/core';
import { Logger, ConsoleLogger, LocalStorageLogger, WebAPILogger } from './logger';
import { ConfigService } from '../services/config.service';
import { LoggerConfig, IConfig } from '../models/config';
import { BehaviorSubject, Observable } from 'rxjs';

class LogPublisherConfig {
  loggerName: string;
  loggerLocation: string;
  isActive: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class LoggerRegistrarService {

  private loggers: Logger[] = [];
  private areLoggersRegistered: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private configService: ConfigService) {
    this.configService.getConfigStatus().subscribe(status => {
      if (status) {
        const config: IConfig = this.configService.getGlobalConfig();
        const loggerConfigurations: LoggerConfig[] = config.loggers;

        for (const log of loggerConfigurations) {
          if (log.isActive) {
            switch (log.name) {
              case 'console':
                this.loggers.push(new ConsoleLogger(log.location));
                break;
              case 'webapi':
                this.loggers.push(new WebAPILogger(log.location));
                break;
              case 'localstorage':
                this.loggers.push(new LocalStorageLogger(log.location));
                break;
            }
          }
        }
        this.areLoggersRegistered.next(true);
      }
    });
  }

  public getRegisteredLoggers(): Logger[] {
    return this.loggers;
  }

  public getLoggerRegistrationStatus(): Observable<boolean> {
    return this.areLoggersRegistered.asObservable();
  }
}


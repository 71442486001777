import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.css']
})
export class CustomSelectComponent<T> implements OnDestroy {

  @Input() public label: string;
  @Input() public id: string;
  @Input() public displayProperty: string;
  public selectedItem: string;
  public selectedIndex: string;

  private _items: T[];
  get items(): T[] {
    return this._items;
  }
  @Input()
  set items(value: T[]) {
    this.selectedItem = `Select ${this.label}`;
    this._items = value;
  }

  @Output() public selectedItemChanged = new EventEmitter<T>();
  @Input() public isLoading: boolean = false;

  constructor() {
  }

  public onSelectedItemChange(item: T): void {

    if (!this.displayProperty) {
      this.selectedItem = item.toString();
      this.selectedIndex = item.toString();
    } else {
      this.selectedItem = item[this.displayProperty];
    }
    this.selectedItemChanged.emit(item);

    var dropdownMenuCls = document.getElementsByClassName('dropdown-menu');
    for (var i = 0; i < dropdownMenuCls.length; i++) {
      if (dropdownMenuCls[i] != null && dropdownMenuCls[i] != undefined) {
        dropdownMenuCls[i].scrollTop = 0;
      }
    }
  }

  ngOnDestroy() {
    this.selectedItem = `Select ${this.label}`;
  }

  mouseOver() {
    var elems = document.querySelector(".active");
    if (elems !== null) {
      elems.classList.remove("active");
    }
  }
  dropDwonClick() {
    var eleMake = document.getElementById("sel" + this.id).id;
    if (eleMake == 'selYear') {
      var yearDrop = document.getElementById("selYear");
      var eleChild = yearDrop.getElementsByClassName("dropdown-menu");
      if (eleChild != null && eleChild.length > 0) {
        var liCount = eleChild[0].getElementsByTagName('li').length;
        for (var i = 0; i < liCount; i++) {
          if (eleChild[0].getElementsByTagName('li')[i].getElementsByTagName('div')[0].innerText == this.selectedIndex) {
            eleChild[0].getElementsByTagName('li')[i].getElementsByTagName('div')[0].classList.add("active");
            return false;
          }
        }
      }
    }
  }
}

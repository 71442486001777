import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminReducer } from './state/admin.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AdminEffects } from './state/admin.effects';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { AdminService } from 'src/app/services/admin.service';
@NgModule({
  declarations: [
    AdminComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    StoreModule.forFeature('vehicleCounts', AdminReducer),
    EffectsModule.forFeature([AdminEffects]),
  ],
  providers: [AuthGuard,
    AdminService   
  ],
})
export class AdminModule { }
